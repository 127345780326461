import "core-js/modules/es.array.push.js";
import { ElMessage } from 'element-plus';
import { mixins } from "@/plugins/mixins";
import { ElLoading } from "element-plus";
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/loading/style/css'

export default {
  name: "deviceController",
  mixins: [mixins],
  components: {},
  data() {
    return {
      showUpload: false,
      inputFile: '',
      switchLoading: false,
      currentId: null,
      detailDrawer: false,
      columns: [{
        label: '仓库',
        prop: 'warehouseName'
      }, {
        label: '序列号',
        prop: 'serialNumber'
      }, {
        label: '厂家',
        prop: 'manufacturer'
      }],
      topButtons: [{
        name: '新增',
        code: 'deviceController_add',
        type: 'primary',
        click: this.add,
        permissions: ['deviceController_add']
      }, {
        name: '导入',
        code: 'deviceController_upload',
        type: 'primary',
        click: this.upload,
        permissions: ['deviceController_upload']
      }],
      linkButtons: [{
        name: '编辑',
        code: 'deviceController_edit',
        click: this.edit,
        permissions: ['dictionary_edit']
      }],
      searchItems: [{
        prop: 'warehouseId',
        label: '仓库',
        type: 'select',
        items: [],
        itemProp: {
          label: 'name',
          value: 'id'
        },
        remote: true,
        loading: false,
        remoteMethod: name => {
          if (name.length >= 1) {
            this.searchItems[0].loading = true;
            this.$api.business.carWarehouse.getList({
              page: 1,
              size: 20,
              name: name
            }).then(res => {
              if (res.code === 200) {
                this.searchItems[0].items = res.data.records;
              }
            }).finally(() => {
              this.searchItems[0].loading = false;
            });
          }
        }
      }, {
        label: '序列号',
        prop: 'serialNumber'
      }, {
        prop: 'manufacturer',
        label: '厂家'
      }]
    };
  },
  methods: {
    downTemplate() {
      const loading = ElLoading.service();
      this.$api.business.deviceController.exportTemplate().then(data => {
        this.blobFile(data, '车辆中控导入模板文件.xlsx', failData => {
          ElMessage.error(failData.message);
        }, () => {
          this.showUpload = false;
        });
      }).finally(() => {
        loading.close();
      });
    },
    fileChange(ev) {
      let selectFile = ev.target.files[0];
      if (selectFile) {
        let name = selectFile.name;
        let type = name.substring(name.lastIndexOf('.') + 1);
        if (type !== 'xls' && type !== 'xlsx') {
          this.$message.error('只能上传excel文件');
          this.inputFile = '';
        } else {
          this.inputFile = selectFile;
        }
      }
    },
    doImp() {
      if (!this.inputFile) {
        ElMessage.error('请选择文件');
        return;
      }
      const loading = ElLoading.service();
      // 构造一个 FormData，把后台需要发送的参数添加到FormData里面，这样就可以直接把formData作为参数传递了
      let formData = new FormData();
      formData.append('file', this.inputFile); //接口需要传递的参数
      this.$api.business.deviceController.importFile(formData).then(res => {
        if (res.code === 200) {
          ElMessage({
            message: res.message,
            type: 'success'
          });
          this.inputFile = '';
          this.showUpload = false;
        } else {
          ElMessage.error(res.message);
        }
      }).finally(() => {
        loading.close();
      });
    },
    add() {
      this.$router.push('/deviceControllerAdd');
    },
    upload() {
      this.showUpload = true;
    },
    edit(row) {
      this.$router.push({
        path: '/deviceControllerAdd',
        query: {
          id: row.id
        }
      });
    }
  }
};